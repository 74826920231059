<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }
  svg#clouds {
    position: fixed;
    bottom: -160px;
    left: -230px;
    z-index: -10;
    width: 1920px;
  }
</style>
<style>
  .bgx {
    background:
        url('../../assets/funrun2023-website.jpg')top center no-repeat;
        background-size: 100% 100% 100% 100%;
        height: 100%;
  }
</style>
<nav class="navbar navbar-expand-sm navbar-dark bg-success fixed-top text-white">
  <div class="container-fluid">
    <a class="navbar-brand" href="javascript:void(0)">
      <img
        width="40"
        alt="Home Page"
        title="Home Page"
        src="../assets/logo.jpg"
        (click)="HomeClick()"
        style="cursor: pointer"
      /> 
    </a>Welcome!
    <div style="flex: 1"></div>
    <button type="button" class="btn btn-primary" (click)="goToGoogleForm()"><i class="fas fa-user-alt"></i><a> Register</a></button>
    <!-- <button type="button" class="btn btn-primary" (click)="createToken()"><i class="fas fa-user-alt"></i><a> Register</a></button> -->
  </div>
</nav>
<!-- style="margin: 82px auto 32px;" -->
<div class="container-fluid" >
  <router-outlet></router-outlet>
  <svg id="clouds" alt="Gray Clouds Background" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
    <path id="Path_39" data-name="Path 39" d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z" transform="translate(142.69 -634.312)" fill="#eee"/>
  </svg>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Registration Closed!</h4>
      </div>
      <div class="modal-body text-center">
        <div class="d-flex justify-content-center">
          <img src="../../assets/sad.png" width="120" />
        </div>
        <h5>Sorry to inform you that registration is already closed. </h5>
        <p>You can visit our facebook page regarding when will be our next event.</p>
  
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" (click)="closeModalStat()">Close</button>
      </div>
    </div>
  </div>
</div>

