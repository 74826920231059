import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpProviderService } from '../app/Service/http-provider.service';
import { formatDate } from '@angular/common';
import cfg from '../assets/cfg.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  displayStyle = "none";
  sdt: string;

  constructor(
    private router: Router,
    private httpProvider: HttpProviderService, 
  ) { }
  title = 'eventSJ';

  ngOnInit(): void { 
    //this.closeReg();
    this.httpProvider.getCfgJson().subscribe(data => {
      //this.sdt = data.closeDate.toString();
      this.sdt = data.registrationclose.closeDate.toString();
    })
  }

  HomeClick(){
    //this.router.navigate(['/Home']);
    let apiToken = sessionStorage.getItem("_token");
    //console.log(apiToken);
    if(apiToken !== null || apiToken !== "") {
      this.httpProvider.postexit(apiToken, {}).subscribe(async data => {
        if(data.body.success) {
          sessionStorage.removeItem("_token");
          this.router.navigate(['/Home']).then(()=> {
            window.location.reload();
          });
       }
      }, async error => {
        console.log(error.message);
        sessionStorage.removeItem("_token");
        this.router.navigate(['/Home']).then(()=> {
          window.location.reload();
        });
      });
    } 
    //sessionStorage.removeItem("_token");
  }


  createToken() {
    var cred = {
      "email" : "kody_15_71@yahoo.com",
      "password" : "sjd@1958",
      "device_name" : "OL"
    }

    this.httpProvider.getTokenX(cred).subscribe(async data => {
      if (data != null && data.body != null) {
        var resultData = data.body;
        sessionStorage.setItem("_token", data.body.data.token); 
        this.router.navigate(['/Register']).then(() => {
          window.location.reload();
        });
      }
      },
        async error => {
          console.log(error.message);
      });
  } 

  goToGoogleForm() {
    let today = new Date();
    let _stoday = formatDate(today.toString(),'yyyy-MM-dd','en-US');

    if(_stoday >= this.sdt) {
      this.displayStyle = "block";
    }
    else {
      this.displayStyle = "none";
      // window.open("https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdef6frT5oUkoxNPy22fX014fFokmp1VRtxWnA7_7JQVoxNxQ%2Fviewform%3Fusp%3Dsend_form&followup=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdef6frT5oUkoxNPy22fX014fFokmp1VRtxWnA7_7JQVoxNxQ%2Fviewform%3Fusp%3Dsend_form&ifkv=AXo7B7UL30JGcdLjW1J2jFlHZnAfzOYdhmvuaiRkN9gulQ7AFu6CZrIKGa0UQIDHV_oTH8AMeT_XmA&ltmpl=forms&osid=1&passive=1209600&service=wise&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S2064122098%3A1694065720331731&theme=glif", "_blank");
      window.open("https://bit.ly/wellnessonthemove", "_blank");
    }
    
  }

  closeModalStat() {
    this.displayStyle = "none";
  }

  closeReg() {
    let today = new Date();
    let _stoday = formatDate(today.toString(),'yyyy-MM-dd','en-US');

    if(_stoday >= this.sdt) {
      this.displayStyle = "block";
    }
    else {
      this.displayStyle = "none";
      this.goToGoogleForm();
    }

  }

}

