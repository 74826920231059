
<style>
  #wrapper {
    width: 400px;
    margin: 10px auto;
    /* background: blue; */
  }
  #wrapper a {
      float: left;
  }
  #wrapper div {
      overflow: hidden;
  }
  #wrapper input {
      width: 100%;
  }
  .bg-header {
    background-color: teal;
    color: white;
  }
</style>
<div style="padding-bottom: 50px;">
  
  <div class="container">
    <div style="padding-bottom: 10px; padding-top: 80px;">
      <button class="btn btn-primary " alt="Back to Home" title="Back to Home" (click)="BackToHome()"><i class="fas fa-home"> </i> Back to Home</button>
    </div>
    <div class="card">
      <h5 class="card-header bg-success" style="color: white">Registration Form</h5>
      <div class="card-body">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label>First Name</label>
            <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" maxlength="16"/>
            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                <div *ngIf="f.firstname.errors.required">First Name is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Middle Initial</label>
            <input type="text" formControlName="middleinitial" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.middleinitial.errors }" maxlength="1"/>
            <div *ngIf="submitted && f.middleinitial.errors" class="invalid-feedback">
                <div *ngIf="f.middleinitial.errors.required">Middle Initial is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Last Name</label>
            <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" maxlength="16"/>
            <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                <div *ngIf="f.lastname.errors.required">Middle Initial is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Birthdate</label>
            <input type="date" formControlName="birthdate" class="form-control" (change)="onChangeEvent($event)" [ngClass]="{ 'is-invalid': submitted && f.birthdate.errors }"/>
            <div *ngIf="submitted && f.birthdate.errors" class="invalid-feedback">
              <div *ngIf="f.birthdate.errors.required">Birthdate is required</div>
              <div *ngIf="f.birthdate.touched && f.birthdate.invalid" [ngClass]="{ 'is-invalid': submitted && f.birthdate.errors }"></div>
            </div>
          </div>
          <div class="form-group">
            <label>Age</label>
            <input type="text" disabled formControlName="age" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.age.errors }" maxlength="2"/>
            <div *ngIf="submitted && f.age.errors" class="invalid-feedback">
                <div *ngIf="f.age.errors.required">Age is required</div>
                <div *ngIf="f.age.errors.pattern">Enter only number.</div>
                <div *ngIf="f.age.touched && f.age.invalid" [ngClass]="{ 'is-invalid': submitted && f.age.errors }"></div>
            </div>
          </div>
          <div *ngIf="_age <= 12 || _age == 0">
            <div class="form-group">
              <label>Guardian Name</label>
              <input type="text" formControlName="guardianname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.guardianname.errors }"/>
              <div *ngIf="submitted && f.guardianname.errors" class="invalid-feedback">
                <div *ngIf="f.guardianname.errors.required">Guardian Name is required</div>
                <div *ngIf="f.guardianname.touched && f.guardianname.invalid" [ngClass]="{ 'is-invalid': submitted && f.guardianname.errors }"></div>
              </div>
            </div>
            <div class="form-group">
              <label>Guardian Relationship</label>
              <input type="text" formControlName="guardianrelation" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.guardianrelation.errors }"/>
              <div *ngIf="submitted && f.guardianrelation.errors" class="invalid-feedback">
                <div *ngIf="f.guardianrelation.errors.required">Guardian Relationship is required</div>
                <div *ngIf="f.guardianrelation.touched && f.guardianrelation.invalid" [ngClass]="{ 'is-invalid': submitted && f.guardianrelation.errors }"></div>
              </div>
            </div>
            <div class="form-group">
              <label>Guardian Mobile No</label>
              <input type="text" formControlName="guardianmobileno" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.guardianmobileno.errors }" maxlength="11" (keypress)="onKeyPress($event)"/>
              <div *ngIf="f.guardianmobileno.hasError('nonNumeric')" style="color:red">
                Please enter only numeric values.
              </div>
              <div *ngIf="submitted && f.guardianmobileno.errors" class="invalid-feedback">
                <div *ngIf="f.guardianmobileno.errors.required">Guardian Mobile No is required</div>
                <div *ngIf="f.guardianmobileno.errors.pattern">Enter only number.</div>
                <div *ngIf="f.guardianmobileno.touched && f.guardianmobileno.invalid" [ngClass]="{ 'is-invalid': submitted && f.guardianmobileno.errors }"></div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Gender</label>
            <select formControlName="gender" class="form-select" [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                <option value=""></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
            </select>
            <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                <div *ngIf="f.gender.errors.required">Gender is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Barangay</label>
            <input type="text" formControlName="barangay" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.barangay.errors }" maxlength="30"/>
            <div *ngIf="submitted && f.barangay.errors" class="invalid-feedback">
                <div *ngIf="f.barangay.errors.required">Barangay is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Town/Municipality</label>
            <input type="text" formControlName="municipality" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.municipality.errors }" maxlength="30"/>
            <div *ngIf="submitted && f.municipality.errors" class="invalid-feedback">
                <div *ngIf="f.municipality.errors.required">Town/Municipality is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Province</label>
            <input type="text" formControlName="province" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.province.errors }" maxlength="30"/>
            <div *ngIf="submitted && f.province.errors" class="invalid-feedback">
                <div *ngIf="f.province.errors.required">Province is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Km To Run</label>
            <select formControlName="kmtorun" id="kmtorun" class="form-select" [ngClass]="{ 'is-invalid': submitted && f.kmtorun.errors }" (change)="onChangeDistance($event.target)">
              <option [ngValue]=""></option>
              <option *ngFor="let options of _dist" [ngValue]="options.value">{{ options.name }}</option>
            </select>
            <div *ngIf="submitted && f.kmtorun.errors" class="invalid-feedback">
                <div *ngIf="f.kmtorun.errors.required">Km to Run is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Registration Type</label>
            <select formControlName="registrationtype" class="form-select" [ngClass]="{ 'is-invalid': submitted && f.registrationtype.errors }" (change)="onChangeRegType($event)">
              <option [ngValue]=""></option>
              <option *ngFor="let opt of _ptype" [ngValue]="opt.value">{{ opt.name }}</option>
            </select>
            <div *ngIf="submitted && f.registrationtype.errors" class="invalid-feedback">
              <div *ngIf="f.registrationtype.errors.required">Registration Type is required</div>
              <div *ngIf="f.registrationtype.touched && f.registrationtype.invalid" [ngClass]="{ 'is-invalid': submitted && f.registrationtype.errors }"></div>
            </div>
          </div>
          <div class="form-group">
            <label>Fee (Php)</label>
            <input type="text" formControlName="fee" class="form-control" disabled [ngClass]="{ 'is-invalid': submitted && f.fee.errors }"/>
            <div *ngIf="submitted && f.fee.errors" class="invalid-feedback">
              <div *ngIf="f.fee.errors.required">Fee is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Mobile Number <i>(ex: 09178009112)</i></label>
            <input type="text" formControlName="mobileno" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobileno.errors }" maxlength="11" (keypress)="onKeyPress($event)"/>
            <div *ngIf="submitted && f.mobileno.errors" class="invalid-feedback">
                <div *ngIf="f.mobileno.errors.required">Mobile Number is required</div>
                <div *ngIf="f.mobileno.errors.pattern">Enter only number.</div>
                <div *ngIf="f.mobileno.errors.minlength">Enter 11 digit number</div>
            </div>
          </div>
          <div class="form-group">
            <label>Email Address</label>
            <input type="text" formControlName="emailaddress" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailaddress.errors }"/>
            <div *ngIf="submitted && f.emailaddress.errors" class="invalid-feedback">
              <div *ngIf="f.emailaddress.errors.required">Email Address is required</div>
              <div *ngIf="f.emailaddress.errors.email">Please enter valid email address</div>
              <div *ngIf="f.emailaddress.touched && f.emailaddress.invalid" [ngClass]="{ 'is-invalid': submitted && f.emailaddress.errors }"></div>
            </div>
          </div>
          <div class="form-group">
            <label>Shirt Size</label>
            <select formControlName="shirtsize" class="form-select" [ngClass]="{ 'is-invalid': submitted && f.shirtsize.errors }">
              <option value=""></option>
              <option value="XS">Extra Small</option>
              <option value="S">Small</option>
              <option value="M">Medium</option>
              <option value="L">Large</option>
              <option value="XL">Extra Large</option>
            </select>
            <div *ngIf="submitted && f.shirtsize.errors" class="invalid-feedback">
              <div *ngIf="f.shirtsize.errors.required">Shirt Size is required</div>
              <div *ngIf="f.shirtsize.touched && f.shirtsize.invalid" [ngClass]="{ 'is-invalid': submitted && f.shirtsize.errors }"></div>
            </div>
          </div>
          <div class="form-group">
            <label>Person to Contact in case of Emergency</label>
            <input type="text" formControlName="emergcallperson" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emergcallperson.errors }"/>
            <div *ngIf="submitted && f.emergcallperson.errors" class="invalid-feedback">
              <div *ngIf="f.emergcallperson.errors.required">Name is required</div>
              <div *ngIf="f.emergcallperson.touched && f.emergcallperson.invalid" [ngClass]="{ 'is-invalid': submitted && f.emergcallperson.errors }"></div>
            </div>
          </div>
          <div class="form-group">
            <label>Mobile No to Contact in case of Emergency</label>
            <input type="text" formControlName="emergpersonmobileno" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emergpersonmobileno.errors }" maxlength="11" (keypress)="onKeyPress($event)"/>
            <div *ngIf="submitted && f.emergpersonmobileno.errors" class="invalid-feedback">
              <div *ngIf="f.emergpersonmobileno.errors.required">Mobile No is required</div>
              <div *ngIf="f.emergpersonmobileno.errors.minlength">Enter 11 digit number</div>
              <div *ngIf="f.emergpersonmobileno.touched && f.emergpersonmobileno.invalid" [ngClass]="{ 'is-invalid': submitted && f.emergpersonmobileno.errors }"></div>
            </div>
          </div>
          <div class="form-group">
            <label>Upload ID's <br><i>(Student ID, SJ Greencard / SJ Employee ID, any Gov't ID for Regular Customers)</i></label>
            <input type="file" accept="image/jpeg, image/jpg, image/bmp, image/png, image/gif" formControlName="identificationcard" class="form-control" (change)="onFileSelected($event)" name="identificationcard" [ngClass]="{ 'is-invalid': submitted && f.identificationcard.errors }">
            <div *ngIf="submitted && f.identificationcard.errors" class="invalid-feedback">
              <div *ngIf="f.identificationcard.errors.required">ID is required</div>
              <div *ngIf="f.identificationcard.touched && f.identificationcard.invalid" [ngClass]="{ 'is-invalid': submitted && f.identificationcard.errors }"></div>
              <div *ngIf="f.identificationcard.hasError('unsupportedFileType')">
                Unsupported file format. Please select a valid image (jpeg, jpg, bmp, png, gif).
              </div>
            </div>
            
          </div>
          <br>
          <!-- Buttons -->
          <div class="card-footer d-flex justify-content-center" style="margin-top: 20px">
              <button class="btn btn-success" [disabled]="isButtonDisabled">Register</button><span>&nbsp;&nbsp;</span>
              <button class="btn btn-secondary" type="reset" (click)="onReset()">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-success">
          <h5 class="modal-title" style="color:white">{{ stat_header }}</h5>
        </div>
        <div class="modal-body">
          <div id="imgSuccess" class="d-flex justify-content-center" [ngStyle]="{'display': displayImg}"><img [src]="imageSuccess" width="120"></div>
          <!-- <h5 class="text-center">{{ stat_message }}</h5> -->
          <h5 class="text-center" [innerHTML]="formattedText"></h5>
    
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" 
                  (click)="closePopup()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id="modalRegClose" class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': displayStylex}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-header">
          <h5 class="modal-title">Registration Closed!</h5>
        </div>
        <div class="modal-body">
          <h5>Sorry to inform you that registration has already closed. </h5>
          <p>You can visit our facebook page regarding when will be our next event.</p>
    
        </div>
        <div class="modal-footer">
          
        </div>
      </div>
    </div>
  </div>

  
</div>
