import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebApiService } from './web-api.service';
import { HttpClient } from "@angular/common/http";

// Production
//var apiUrl = "https://event-api.stjosephdrug.com";

// Development
var apiUrl = "http://localhost:8000";

var httpLink = {
  postRegistration: apiUrl + "/api/registration",
  getToken: apiUrl + "/api/login", 
  logout: apiUrl + "/api/logout",
  uploadImage: apiUrl + "/api/uploadImg"
}

@Injectable({
  providedIn: 'root'
})
export class HttpProviderService {

  constructor(
    private webApiService: WebApiService,
    private http: HttpClient
    ) { }

  public postRegistration(model: any, token: string): Observable<any> {
    return this.webApiService.postReg(httpLink.postRegistration, model, token);
  }

  public getTokenX(model: any): Observable<any> {
    return this.webApiService.postLogin(httpLink.getToken,model);
  }

  public postexit(token: string, model: any): Observable<any> {
    return this.webApiService.postLogout(httpLink.logout, token, model);
  }

  public getCfgJson(): Observable<any> {
    return this.http.get("../../assets/cfg.json");
  }

  public uploadImg(data: FormData, token: string): Observable<any> {
    return this.webApiService.postImage(httpLink.uploadImage, data, token)
  }

  public uploadImage(image: File): Observable<any> {
    const formData = new FormData();

    formData.append('image', image);

    return this.http.post('/api/v1/image-upload', formData);

  }
}
