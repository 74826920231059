import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpProviderService } from '../Service/http-provider.service';
import { formatDate } from '@angular/common';
import cfg from '../../assets/cfg.json';
import moment from 'moment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;

  stat_message : string = "";
  stat_header: string = "";

  _age : number = 0;

  displayStyle = "none";
  displayStylex = "none";
  displayImg = "none";
  imageSuccess: string = "";

  _xToken: string = "";

  selectedFile: File | null = null;

  _regid: any;
  formattedText: SafeHtml;

  _dist: any[];
  _ptype: any[];
  _fees: any[];

  txtFees: number;
  _km: string;
  _type: string;
  options: any;

  isButtonDisabled: boolean = false;
  imgError: boolean;
  
  constructor(
    private router: Router, 
    private httpProvider: HttpProviderService, 
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer
  ) { 

    this.httpProvider.getCfgJson().subscribe(data => {
      //this.sdt = data.closeDate.toString();
      //this.sdt = data.registrationclose.closeDate.toString();
      this._dist = data.distance;
      this._ptype = data.participanttype;
      this._fees = data.fees;
    })

  }

  ngOnInit(): void {
    //this.createToken();
    this.closeReg();
    
    this.registerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      middleinitial: ['', Validators.required],
      lastname: ['', Validators.required],
      birthdate: ['', Validators.required],
      age: ['', Validators.required],
      guardianname:['', Validators.required],
      guardianrelation: ['', Validators.required],  
      guardianmobileno: ['', [Validators.required, this.numericOnlyValidator, Validators.minLength(11)]],        
      gender: ['', Validators.required],
      barangay: ['', Validators.required],
      municipality: ['', Validators.required],
      province: ['', Validators.required],
      kmtorun: ['', Validators.required],
      registrationtype: ['', Validators.required],
      mobileno: ['', [Validators.required, Validators.minLength(11)]],
      emailaddress: ['',[Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      shirtsize: ['', Validators.required],
      emergcallperson: ['',Validators.required],
      emergpersonmobileno: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11)]],
      identificationcard: [null,[Validators.required, this.allowedImageFileTypeValidator]],
      fee: ['', Validators.required]
    });

    this.registerForm.get('age').valueChanges.subscribe(val => {
      if(val < 12) {
        this.registerForm.controls['guardianname'].setValidators([Validators.required]);
        this.registerForm.controls['guardianrelation'].setValidators([Validators.required]);
        this.registerForm.controls['guardianmobileno'].setValidators([Validators.required, Validators.minLength(11)])
      }
      else {
        this.registerForm.controls['guardianname'].clearValidators();
        this.registerForm.controls['guardianrelation'].clearValidators();
        this.registerForm.controls['guardianmobileno'].clearValidators();
      }
      this.registerForm.controls['guardianname'].updateValueAndValidity();
      this.registerForm.controls['guardianrelation'].updateValueAndValidity();
      this.registerForm.controls['guardianmobileno'].updateValueAndValidity();
    });

  }

  get f() { return this.registerForm.controls; }

  BackToHome() {
    let apiToken = sessionStorage.getItem("_token");
    //console.log(apiToken);
    if(apiToken !== null || apiToken !== "") {
      this.httpProvider.postexit(apiToken, {}).subscribe(async data => {
        if(data.body.success) {
          sessionStorage.removeItem("_token");
          this.router.navigate(['/Home']).then(()=> {
            window.location.reload();
          });
       }
      }, async error => {
        console.log(error.message);
        sessionStorage.removeItem("_token");
        this.router.navigate(['/Home']).then(()=> {
          window.location.reload();
        });
      });
    } 
  }

  onSubmit() {
    this.submitted = true;

    let dt = new Date();
    //console.log(dt);
    let sdt = formatDate(dt.toString(), 'yyyy-MM-dd H:mm:ss','en-US');

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    else {
      this.isButtonDisabled = true;
      this._regid = this.createID();
    
      let jsonData = {
        "firstname" : this.registerForm.controls['firstname'].value,
        "middleinitial" : this.registerForm.controls['middleinitial'].value,
        "lastname" : this.registerForm.controls['lastname'].value,
        "barangay" : this.registerForm.controls['barangay'].value,
        "municipality" : this.registerForm.controls['municipality'].value,
        "province" : this.registerForm.controls['province'].value,
        "birthdate" : this.registerForm.controls['birthdate'].value,
        "age" : this.registerForm.controls['age'].value,
        "gender" : this.registerForm.controls['gender'].value,
        "kmtorun" : this.registerForm.controls['kmtorun'].value,
        "registrationtype" : this.registerForm.controls['registrationtype'].value,
        "mobileno" : this.registerForm.controls['mobileno'].value,
        "cardserialno" : "",
        "regid" : this._regid,
        "posted" : 1,
        "paid" : "N",
        "guardianname" : this.registerForm.controls['guardianname'].value,
        "guardianrelation" : this.registerForm.controls['guardianrelation'].value, 
        "guardianmobileno" : this.registerForm.controls['guardianmobileno'].value,
        "emailaddress" : this.registerForm.controls['emailaddress'].value,
        "emergcallperson": this.registerForm.controls['emergcallperson'].value,
        "emergpersonmobileno": this.registerForm.controls['emergpersonmobileno'].value,
        "identificationcard" : this.registerForm.controls['identificationcard'].value,
        "shirtsize": this.registerForm.controls['shirtsize'].value,
        "datecreated" : sdt
      } 

      let _token = sessionStorage.getItem("_token");

      this.httpProvider.postRegistration(jsonData, _token).subscribe(async data => {
        if(data != null && data.body != null) {
          var resultData = data.body;
          this.displayImg = "block";
          this.imageSuccess = "../../assets/check.png"
          this.stat_header = "Registration Successfull!"
          this.stat_message = "<br><h4>Registration No:</h4><br><h3>" + this._regid + "</h3>" + "<br>You have successfully registered to our fun run. <br>You can now pay to our branches near you. <br>We accept Cash, GCash and Credit Card."
          this.formattedText = this.sanitizer.bypassSecurityTrustHtml(this.stat_message);
          this.uploadImage(this.selectedFile, this._regid);
          this.openPopup(this.stat_message,this.stat_header)
        }
      },
      async error => {
        this.stat_message = error.message;
        this.stat_header = "Error";
        this.openPopup(this.stat_message, this.stat_header)
      });
    }
    
  }

  

  numericOnlyValidator(control: AbstractControl): { [key: string]: any } | null {
    const input = control.value;
    const numericPattern = /^[0-9]*$/;

    if (!numericPattern.test(input)) {
      return { nonNumeric: true };
    }

    return null; // Validation passed
  }

  onKeyPress(event: KeyboardEvent): void {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  computeAge(dateOfBirth: any): number {
    return moment().diff(dateOfBirth, 'years');
  }

  createID() {
    let todayx = new Date();
    let stoday = formatDate(todayx.toString(), 'yyyyMMddHmmss','en-US');
    return "OL-" + stoday;
  }

  onChangeEvent(event: any){
    let age = this.computeAge(event.target.value);
    this._age = age;
    this.registerForm.get('age').setValue(age);
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  openPopup(msg : string, status: string) {
    this.displayStyle = "block";
  }

  fltrFees(data: any[], p1: string, v1: any, p2: string, v2: string) : any[] {
    return data.filter((t) => t[p1] == v1 && t[p2] == v2);
  }

  onChangeDistance(sel: any) {
    if(sel.value == '') {
      this.registerForm.get('fee').setValue('');
      this._km = "";
      return;
    }
    this._km = sel.value.split(":");
    this._km = this._km[1];

    var _fees: any[];
    if(this._type != undefined || this._km != undefined)  {
      _fees = this.fltrFees(this._fees, 'distancekm', this._km, 'participanttype', this._type);
      var amnt = Object.values(_fees)[0].fee.toString();
      this.registerForm.get('fee').setValue(amnt);
    } 
  }

  onChangeRegType(event: any) {
    this._type = event.target.selectedOptions[0].innerHTML;

    if(this._type == '') {
      this.registerForm.get('fee').setValue('');
      return;
    }

    if(this._km == undefined) {
      this.registerForm.get('fee').setValue('');
      return;
    }
    var _fees: any[];

    if(this._km != undefined || this._type != undefined) {
      _fees = this.fltrFees(this._fees, 'distancekm', this._km, 'participanttype', this._type);
      var amnt = Object.values(_fees)[0].fee.toString();
      this.registerForm.get('fee').setValue(amnt);
    } 

    //console.log(this._km);
  }

  closeReg() {
    let today = new Date();
    let _stoday = formatDate(today.toString(),'yyyy-MM-dd','en-US');

    if(_stoday >= cfg.registrationclose.closeDate) {
      this.displayStylex = "block";
    }
    else {
      this.displayStylex = "none";
    }

  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];

    const validationResult = this.allowedImageFileTypeValidator(this.selectedFile);

    if(validationResult == null) {
      this.registerForm.controls['identificationcard'].setErrors(null);
    }
    else {
      this.registerForm.controls['identificationcard'].setErrors({ unsupportedFileType: true });
    }
    
  }

  allowedImageFileTypeValidator(control: File) {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/bmp', 'image/png', 'image/gif'];
    const inputExt = control.type;
    if(allowedTypes.includes(inputExt)) {
      return null;
    } else {
      return { unsupportedFileType: true }; // Unsupported file type
    }
  }

  uploadImage(_frmdt: File, _regid: string ): void {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('image', _frmdt);
      formData.append('regid', _regid);

      let _token = sessionStorage.getItem("_token");

      this.httpProvider.uploadImg(formData, _token).subscribe(async data => {
        //console.log(data);
      },
      async error => {
        //console.log(error);
      });
    }
  }

  closePopup() {
    this.displayStyle = "none";
    
    let _token = sessionStorage.getItem("_token");

    this.httpProvider.postexit(_token, {}).subscribe(async data => {
      if(data.body.success) {
        console.log(data.body);
        sessionStorage.removeItem("_token");
        this.router.navigate(['/Home']).then(()=> {
          window.location.reload();
        });
     }
    }, async error => {
      //console.log(error.message);
      this.stat_message = error.message
      this.stat_header = "Error"
      this.openPopup(this.stat_header,this.stat_message);
      sessionStorage.removeItem("_token");
      this.router.navigate(['/Home']).then(()=> {
        window.location.reload();
      });
    });
  }

}

