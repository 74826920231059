import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import cfg from '../../assets/cfg.json';
import { HttpProviderService } from '../Service/http-provider.service';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  displayStyle = "none";
  sdt: string;

  constructor(
    private http: HttpProviderService,
  ) { }

  ngOnInit(): void {
    this.http.getCfgJson().subscribe(data => {
      this.sdt = data.registrationclose.closeDate.toString();
    });
  }
  

  downloadPdf(pdfUrl: string, pdfName: string ) {
    let today = new Date();
    let _stoday = formatDate(today.toString(),'yyyy-MM-dd','en-US');

    if(_stoday >= this.sdt) {
      this.displayStyle = "block";
    }
    else {
      this.displayStyle = "none";
      FileSaver.saveAs(pdfUrl, pdfName);
    }
    
  }

  goToGoogleForm() {
    let today = new Date();
    let _stoday = formatDate(today.toString(),'yyyy-MM-dd','en-US');

    if(_stoday >= this.sdt) {
      this.displayStyle = "block";
    }
    else {
      this.displayStyle = "none";
      // window.open("https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdef6frT5oUkoxNPy22fX014fFokmp1VRtxWnA7_7JQVoxNxQ%2Fviewform%3Fusp%3Dsend_form&followup=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdef6frT5oUkoxNPy22fX014fFokmp1VRtxWnA7_7JQVoxNxQ%2Fviewform%3Fusp%3Dsend_form&ifkv=AXo7B7UL30JGcdLjW1J2jFlHZnAfzOYdhmvuaiRkN9gulQ7AFu6CZrIKGa0UQIDHV_oTH8AMeT_XmA&ltmpl=forms&osid=1&passive=1209600&service=wise&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S2064122098%3A1694065720331731&theme=glif", "_blank");
      window.open("https://bit.ly/wellnessonthemove","_blank")
    }
  }

  closeModalStat() {
    this.displayStyle = "none";
  }

}
